import React from "react"
import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'

export default function Example() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Postulez-ici pour devenir plombier partenaire blicko</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              En France, environ 3,7 millions de dépannage en plomberie sont effectués chaque année. La vétusté des installations est en cause pour de nombreux sinistres.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Vous rêvez de nous rejoindre et de renforcer notre équipe de plombiers ? Nous rêvons aussi de vous rencontrer et de vous accueillir dans notre réseau de plombiers.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Et si, en quelques semaines, vous donniez un grand coup de boost à votre activité de plombier ?
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p><strong>blicko</strong></p>
                  <p>22 Rue de Chavril</p>
                  <p>69110 Sainte-Foy-Les-Lyon</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Téléphone</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">09 74 37 14 40</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon className="h-5 inline" />blicko.fr</span>
                </dd>
              </div>
            </dl>

          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <h2 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">Candidature plombier blicko</h2>
            <form action="https://formulaire.koudepouce.fr/blicko/recrutement/contact/" method="POST" className="py-4 gap-y-6">
              <div className="pb-4">
                <label htmlFor="prenom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Prénom*"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Nom*"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="siren" className="sr-only">
                  Siren
                </label>
                <input
                  type="number"
                  name="siren"
                  id="siren"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Siren"
                />
              </div>
              <div className="pb-4">
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="number"
                  name="cp"
                  id="cp"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Code postal*"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="number"
                  name="telephone"
                  id="telephone"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Téléphone*"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Email*"
                  required
                />
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  required
                />J'accepte les <a href="/nous-connaitre/legal/cgu/" className="text-blue-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation*</a>
              </div>


              <div className="hidden">
                <label htmlFor="tellepro" className="sr-only">
                  Téllépro
                </label>
                <input
                  type="text"
                  name="tellepro"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téllépro"
                />
              </div>


              <div className="pb-4">
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Envoyer
                </button>
              </div>
            </form>
            <p className="mt-3 text-xs leading-6 text-gray-400">
              Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par blicko visant à traiter votre demande. Elles sont conservées pendant 3 ans et sont destinées au service marketing et au service commercial de blicko. <a className="text-blue-500" href="/nous-connaitre/legal/mentions-legales/" target="_blank">Nos mentions légales sont accessibles ici</a>. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : blicko, SAS Expertise Plomberie, Service de la protection des données,  22 Rue de Chavril, 69110 Sainte-Foy-lès-Lyon ou par e-mail sur contact<AtSymbolIcon className="h-5 inline" />blicko.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
