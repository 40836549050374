import React from "react"
import { Helmet } from "react-helmet"

//import Layout from "../kdp_theme/Layout"
import KdpBanner from "../../../components/banner"
import KdpHeader from "../../../components/headers/header"
import KdpContactForm from "../../../components/contacts/partenaireForm"
import KdpFooter from "../../../components/footers/footer"


export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Postulez pour devenir Plombier Partenaire blicko | Le plombier 3.0</title>
        <meta name="description" content="Candidature Plombier blicko | Retrouvez la démarche et le formulaire de contact pour postuler comme plombier chez blicko" />
      </Helmet>
      <header>
        <KdpBanner />
        <KdpHeader />
        <KdpContactForm />
        <KdpFooter />
      </header>
      <main>

      </main>
    </div>
  );
}

